import React from 'react'
import { useParams } from 'react-router-dom'




function Items() {

    let { id } = useParams()
  return (
    <div className='flex justify-center items-center   min-w-screen min-h-screen'>


          {id}
        </div>
  )
}

export default Items