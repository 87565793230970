import React from 'react'
import GCss from './globalCss';
import SectionHead from './SectionHead';

function Service({id}) {



    const commonDivCss = '  sm:min-h-72 hover:shadow-[0_10px_100px_20px_rgba(10,58,89)] transition-all duration-1000 ease-in-out min-h-48 border  rounded-xl   p-2 backdrop-blur-sm bg-white/5   border-gray-500 bg-white/3  '
    const itemsCss = 'px-3 py-2 mx-2 my-1 text-sm rounded-full bg-[#020817] hover:bg-indigo-600 transition-all duration-300 ease-in-out';
    const subdivCss = "w-full flex flex-wrap"



    return (
        <>

        <div id={id} className={GCss.sectionType1}
        >
            <div className=' h-20 w-5'></div>
            {/* <div className={GCss.heading1}></div> */}
            <SectionHead name={"Our Services"} content={" Our comprehensive approach ensures that we provide tailored solutions to meet your every need."}/>
            

{/*   */}
            {/* main */}
            <div className='w-full grid lg:grid-cols-3 sm:grid-cols-2  grid-cols-1  gap-4' >
                <div className={` ${GCss.serviceDiv1} `}>
                    {/* headind */}
                    <h1>WEB </h1>
                    <div className={GCss.paragraph3}>
                    <p>we specialize in delivering exceptional web experiences, innovative applications, and impactful digital solutions to elevate your online presence.</p>
                     </div>
                         
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>Websites</div>
                        <div className={` ${itemsCss} `}>Webapp</div>
                        <div className={` ${itemsCss} `}>Ecommerce</div>
                        <div className={` ${itemsCss} `}>Wordpress</div>
                        <div className={` ${itemsCss} `}>Dashboard</div>
                        <div className={` ${itemsCss} `}>Bwoser Extension</div>
                        <div className={` ${itemsCss} `}>and more . . . </div>
                    </div>
                </div>
                <div className={` ${GCss.serviceDiv1} `}>
                    <h1>Mobile Apps</h1>
                    <div className={GCss.paragraph3}>
                    <p>we specialize in creating seamless mobile experiences for Android and iOS. Let us bring your app ideas to life with precision, creativity, and expertise.</p>
                    </div>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>Android apps</div>
                        <div className={` ${itemsCss} `}>IOS Apps</div>

                    </div>
                </div>
                <div className={` ${GCss.serviceDiv1} `}>
                    <h1>Digital Marketing</h1>
                    <div className={GCss.paragraph3}>
                    <p>we excel in driving digital success through strategic marketing campaigns, captivating content creation, and data-driven insights.</p>
                    </div>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>SEO</div>
                        <div className={` ${itemsCss} `}>Email Marketing</div>
                        <div className={` ${itemsCss} `}>Social Media Marketing</div>
                        <div className={` ${itemsCss} `}>Youtube Marketing</div>
                        <div className={` ${itemsCss} `}>Google Marketing</div>
                        <div className={` ${itemsCss} `}>and more . . . </div>
                    </div>
                </div>

                <div className={` ${GCss.serviceDiv1} `}>
                    <h1>Desktop Apps</h1>
                    <div className={GCss.paragraph3}>
                   <p>Zunosoft creates powerful desktop apps for Windows, Linux, and Mac, blending functionality with intuitive design for seamless user experiences.</p>
                    </div>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>for Windows Os</div>
                        <div className={` ${itemsCss} `}>for Mac Os</div>
                        <div className={` ${itemsCss} `}>for Linux Os</div>
                    </div>
                </div>
                <div className={` ${GCss.serviceDiv1} `}>
                    <h1>3D Modeling</h1>
                    <div className={GCss.paragraph3}>
                        <p>Zunosoft excels in home 3D design, product prototyping, and immersive visualizations, empowering you to create with realism and efficiency</p>
                    </div>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>House 3D </div>
                        <div className={` ${itemsCss} `}>Product 3D prototype</div>
                        <div className={` ${itemsCss} `}>Custom 3D design </div>
                        <div className={` ${itemsCss} `}>and more . . . </div>

                    </div>
                </div>
                <div className={` ${GCss.serviceDiv1} `}>
                    <h1>Others</h1>
                    <div className={GCss.paragraph3}>
                    <p>Unlock endless possibilities with Zunosoft's 'Other' category, from Telegram bots to AI chat integrations and beyond.</p>
                    </div>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>Telegram Bot</div>
                        <div className={` ${itemsCss} `}>Ai Chatbot in websites</div>
                        <div className={` ${itemsCss} `}>and more . . . </div>
                    </div>
                </div>


            </div>
            <br />
            {/*main, but in mobile view it not show, it will show in all services */}
            {/* <div className='w-full grid lg:grid-cols-3  md:grid hidden  grid-cols-1 lg:gap-4 md:gap-4 gap-4' >

                <div className={` ${commonDivCss} `}>
                    <h1>desktops</h1>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>for Windows Os</div>
                        <div className={` ${itemsCss} `}>for Mac Os</div>
                        <div className={` ${itemsCss} `}>forLinux Os</div>
                    </div>
                </div>
                <div className={` ${commonDivCss} `}>
                    <h1>3d Modeling</h1>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>House 3D </div>
                        <div className={` ${itemsCss} `}>Product 3D prototype</div>
                        <div className={` ${itemsCss} `}>forLinux Os</div>

                    </div>
                </div>
                <div className={` ${commonDivCss} `}>
                    <h1>others</h1>
                    <div className={` ${subdivCss} `}>
                        <div className={` ${itemsCss} `}>Telegram Bot</div>
                        <div className={` ${itemsCss} `}>Ai Chatbot in websites</div>
                    </div>
                </div>



            </div> */}
            {/* websitesd
desktops
mobileapps
integration
mix-telegram bot
digital marketing */}





        </div>
        </>
    )
}

export default Service