import React from 'react'
import globalCss from './globalCss'
import SectionHead from './SectionHead'

function AboutUs({id}) {
    // const commonDivCss = 'hover:shadow-[0_10px_100px_20px_rgba(10,58,89)] transition-all duration-300 ease-in-out min-h-48   p-2 backdrop-blur-sm bg-white/5   border-gray-500 bg-white/3  '
    const commonDivCss=globalCss.divDesign1

    const content = [
        { logo: "", heading: "Customer Success", text: "We are committed to our clients’ success, and we work tirelessly to ensure that our solutions are tailored to their needs and deliver tangible results." },
        { logo: "", heading: "Quick Iterations", text: "Our agile methodology enables us to respond to changes promptly and incorporate feedback effectively, ensuring we meet our clients' needs timely and accurately." },
        { logo: "", heading: "AI & Data Driven", text: "Our approach is grounded in data-driven insights, which enables us to predict trends, personalize experiences, and make strategic decisions that yield tangible results." },
        { logo: "", heading: "Always Learning ", text: "Our team stays abreast of the latest technologies, methodologies, and best practices, ensuring that our solutions are modern, relevant, and effective." },
        { logo: "", heading: "Transparency ", text: "We believe in transparency and open communication, which is why we keep our clients informed throughout the development process." },
        { logo: "", heading: "Craftsmanship ", text: "We see ourselves not merely as technologists, but also as dedicated artisans meticulously crafting exceptional, transformative digital experiences that truly make a profound difference." },

    ]


    return (
        <>
          <div id={id} className=' h-20 w-5'></div>
        <div  className={`${commonDivCss} flex justify-center items-center md:px-12 px-10 py-20 md:pb-20 flex-col `}>

            <div>
                {/* <h1 className={`${globalCss.heading1} m-10`}></h1> */}
                <SectionHead name={"About Zunosoft"}/>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 max-w-[1200px]'>
                  <p className={`${globalCss.paragraph1} `}>We're dedicated to building innovative solutions with cutting-edge technologies. Our team of experts leverages the latest tools and techniques to create custom applications to meet the unique needs of our clients.</p>
                  <p className={`${globalCss.paragraph1} `}>Whether you're looking to develop a mobile app, a web-based platform, or anything in between, we've got you covered. From ideation to launch, we work closely with our clients to ensure that every project we undertake is a success. Below's are what we value the most.</p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-[1200px]'>

                    {content.map((e) => {
                        return <div className=' p-5'>
                            <span className='text-violet-500 font-bold text-lg'>{e.heading}</span>
                            <span className={`${globalCss.paragraph1}`}>{e.text}</span>

                        </div>
                    }


                    )}
                </div>






            </div>


        </div>
        </>
    )
}

export default AboutUs