import React from 'react'
import GCss from './globalCss'

function SectionHead({name,content}) {
  return (
    <>
    <div className='text-center'>
    <div className={GCss.heading1}>{name}</div>
    <div className={GCss.paragraph2}>{content}</div>
    </div>
    </>
  )
}

export default SectionHead