import React from 'react'
import ContactUsBtn from './ContactUs-btn'

function Intro() {
  return (
    <>
      <div className='flex flex-col justify-center items-center max-w-screen h-screen bg-transparent text-center'>
        {/* color: #2ff5e6;
    background-image: -webkit-linear-gradient(0deg, #2ff5e6, #7f3cc2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; */}


        <div className=' h-1/2  flex flex-col justify-end  '>
          <div className=' lg:text-8xl md:text-7xl sm:text-6xl text-5xl font-bold  py-4 text-transparent bg-gradient-to-r from-cyan-400 to-purple-600 bg-clip-text'> Cutting-Edge</div>

          <h1 className=' lg:text-7xl md:text-5xl sm:text-4xl text-3xl  font-bold  p-2'>Software Solution & Marketing</h1>
          <br />
        




        </div>
        <div className=' h-1/2 flex items-center justify-between p-5 flex-col'>
        <div className=' lg:w-128  p-3 text-sm  backdrop-blur-sm bg-white/5 text-gray-400 sm:text-lg  border-gray-500 bg-white/3 rounded-full' > where we specialize in crafting exceptional website experiences, innovative mobile apps, desktop solutions, and impactful digital marketing strategies.</div>
         
           <div className='flex w-full justify-center  m-5'>
            <div className='m-5'>  <ContactUsBtn/></div>
          
            {/* <div className='m-2 sm:m-10 py-2 px-5  bg-gradient-to-r from-cyan-400 to-purple-600  rounded-full'>Contact us
            </div> */}
            <div className='m-5'> <div className='w-32 h-10 min-m-5 sm:w-32 sm:h-10 flex justify-center items-center   rounded-full border-2 border-gray-600 hover:bg-gradient-to-r from-cyan-400 to-purple-600 hover:border-transparent transition-all duration-300 ease-in-out' >Learn More
</div>
            </div>
            </div>
           


          <div className=' flex justify-center items-center  w-12 h-12 rounded-full cursor-pointer   border-2 border-gray-600 hover:border-indigo-500'>V</div>
        </div>
        {/* <div className=''> we excel in crafting exceptional websites, mobile apps, and desktop solutions. Let us elevate your digital presence with innovative strategies and impactful digital marketing solutions.</div> */}
        {/* Experience the difference with our tailored approach to success */}


      </div>

    </>
  )
}

export default Intro