


let heading2="md:text-3xl text-2xl font-bold text-center p-5"
let heading1="md:text-5xl text-4xl font-bold text-center "

const paragraph1=`text-start p-1 pb-5 b my-2 text-gray-300 `
const paragraph2=`text-center p-1 pb-5 my-2 text-gray-300 `
const paragraph3=`text-start min-h-24 p-1 pb-5 b my-2 text-gray-300 border-b-4 border-gray-700`
const sectionType1='text-center sm:p-10 pb-7  '
const sectionType2='text-center sm:p-10 p-5'
// const divDesign1 = 'rounded-xl  hover:shadow-[0_10px_100px_20px_rgba(10,58,89)] transition-all duration-300 ease-in-out min-h-48   p-2 backdrop-blur-sm bg-white/5   border-gray-500  hover:bg-white/5  '
const divDesign1 = 'rounded-xl w-full  p-2 backdrop-blur-sm bg-white/5   border-gray-500  hover:bg-white/5  '
const divDesign2 = 'rounded-xl  w-full   p-2 backdrop-blur-sm bg-white/5   border-gray-500  hover:bg-white/5  '
const serviceDiv1='  min-h-72  hover:shadow-[0_10px_100px_20px_rgba(10,58,89)] transition-all duration-300 ease-in-out min-h-48 border  rounded-xl   p-2 backdrop-blur-sm bg-white/5   border-gray-500 bg-white/3  '


const GCss={serviceDiv1,divDesign2,sectionType2,heading1,paragraph1,paragraph2,paragraph3,sectionType1,divDesign1,heading2}
export default GCss