import React from 'react'

function Footer() {
  return (
    <div className='flex justify-center items-center flex-col  p-10   w-full bg-[#020817] h-58'
    >
      <div>


        <div className='flex   grid md:grid-cols-2 grid-cols-1 gap-4 text-center'>
          {/* <div className='mx-5'><img className='w-12' src="../asset/linkedin.svg" alt="" /></div> */}
          <div className=' flex'>
            <img className='w-8 h-8' src="../asset/phone-call.png" alt="" />
            <span className='text-xl mx-2'>9165881087</span>
          </div>
          <div className=' flex'><img className='w-6 h-6' src="../asset/whatsapp2.png" alt="" />
            <span className='text-xl mx-2'>9165881087</span>
          </div>
         
          <div className=' flex'>
            {/* <img className='w-6 h-6' src="../asset/whatsapp2.png" alt="" /> */}
            <span className='text-xl mx-2'>Mail: <a href='mailto:support@zunosoft.com'> support@zunosoft.com</a></span>
          </div>
       
        </div>
      </div>
      {/* <div className='flex flex-col'>
      <div>Mobile: 9165881087</div>
      <div>Whatsapp: 9165881087</div>
    </div> */}
      <br />
      <address className='pb-5'>Virtual Cowork, MangalCity, VijayNagar, Indore, Madhya Pradesh, Pin-452010, India</address>
      <div className='text-gray-400'>© 2023 Zunosoft . All rights reserved.</div>


    </div>
  )
}

export default Footer