import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Intro from './components/Intro';
import Service from './components/Service';
import IndustryWeServe from './components/IndustryWeServe';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import WhyWe from './components/WhyWe';
import Pricing from './components/Pricing';
import { Route, Routes, useParams } from 'react-router-dom';
import Items from './Pages/Items';

function App() {


  return (
    <div >
      <Header />

      <Routes>
        <Route path='/' element={
          <>
            <Intro id={"section0"} />
           
            <Service id={"section1"} />
            <WhyWe id={"section5"} />
            <Pricing id={"section3"} />
            <AboutUs id={"section4"} />
          </>
        } />
        <Route path='/our-work' element={<h1>aboyurt</h1>} />
        <Route path='/projects/:id' element={<Items/>
       } />

        <Route path='/contact' element={<h1>contact</h1>} />
      </Routes>
      <Footer id={"footer"} />
      {/* <div id='section1'></div> */}
    </div>
  );
}

export default App;
