import React from 'react'
import globalCss from './globalCss'
import SectionHead from './SectionHead'
import SectionHead2 from './SectionHead2'
import Paragraph1 from './Paragraph1'
// import asset from "../public/assets"
function WhyWe({ id }) {
    return (


        <div id={id} className={globalCss.sectionType1}>
              <div className=' h-20 w-5'></div>
            {/* <div className={globalCss.heading2}>Advantage</div> */}
            <SectionHead  name={"Why Choose us"} content={"When you choose use, You will save your time and money."}/>

            <div className={`${globalCss.divDesign1}`}>
            {/* <SectionHead2 name={"Every Thing At One Place"}/> */}
               <Paragraph1>
               <span className={globalCss.paragraph1}>You will get <span className='font-bold p-1 underline text-indigo-400'>Every Thing At One Place </span> that save your much time and money that will help your bussiness to grow easy and fast.</span>
               </Paragraph1>
                    
               

                <div className='w-full  grid lg:grid-cols-5 grid-cols-2 lg:gap-4 gap-2 '>
                    <div className=' flex justify-center items-center'><div className=' flex flex-col justify-center items-center w-full   m-5'><img className='w-full max-w-36  ' src="../asset/web-design.png" alt="" /><span className='text-lg p-3 lg:text-xl'>Design</span></div></div>
                    <div className=' flex justify-center items-center'><div className=' flex flex-col justify-center items-center w-full   m-5'><img className='w-full max-w-36  ' src="../asset/development.png" alt="" /><span className='text-lg p-3 lg:text-xl'>Development</span></div></div>
                    <div className=' flex justify-center items-center'><div className=' flex flex-col justify-center items-center w-full   m-5'><img className='w-full max-w-36  ' src="../asset/test.png" alt="" /><span className='text-lg p-3 lg:text-xl'>Testing</span></div></div>
                    <div className=' flex justify-center items-center'><div className=' flex flex-col justify-center items-center w-full   m-5'><img className='w-full max-w-36  ' src="../asset/deploy.png" alt="" /><span className='text-lg p-3 lg:text-xl'>Deployment</span></div></div>
                    <div className=' flex justify-center items-center'><div className=' flex flex-col justify-center items-center w-full   m-5'><img className='w-full max-w-36  ' src="../asset/digital-marketing.png" alt="" /><span className='text-lg p-3 lg:text-xl'>Digital Marketing</span></div></div>
                </div>
            </div>
        </div>


    )
}

export default WhyWe