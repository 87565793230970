// import { Modal } from 'antd';

import React, { useState } from 'react'
import Modal1 from './Modal';
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
} from 'antd';
import FormItemLabel from 'antd/es/form/FormItemLabel';









function ContactUsBtn() {
const [isModalOpen, setIsModalOpen] = useState(false);
const [componentSize, setComponentSize] = useState('default');
const onFormLayoutChange = ({ size }) => {
  setComponentSize(size);
};
  const showModal = () => {

    // alert('Please')
    setIsModalOpen(true);

  };


  // const transporter = nodemailer.createTransport({
  //   host: "gmail",
  //   port: 587,
  //   secure: false, // Use `true` for port 465, `false` for all other ports
  //   auth: {
  //     user: "kritestemail123@gmail.com",
  //     pass: "mgxw bolv gtwb oapw",
  //   },
  // });

const sendHandler=()=>{
  // transporter.sendMail({
  //   from: 'kritestemail123@gmail.com', // sender address
  //   to: "krishna854192@gmail.com", // list of receivers
  //   subject: "Hello ✔", // Subject line
  //   text: "Hello world?", // plain text body
  //   html: "<b>Hello world?</b>", // html body
  // });
}


  return (
    <>

    <Modal1 isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>

      <div className='text-white p-5'>
      
      <div className='mb-5 text-center'>
        <div  className='text-xl font-bold'>  Request a Quotation or a Support</div>
      
        <div className='text-gray-300'>Let Us Provide You with a Customized Solution and Pricing</div>
        </div>
        
        <div className='mt-1'><span>Name*</span><Input className='p-2 text-md font-bold' placeholder='Your name'  /></div>
        <div className='mt-1'><span>Email*</span><Input className='p-2 text-md font-bold' placeholder='Example@you.com'  /></div> 
        <div className='mt-1'><span>Whatsapp*</span><Input className='p-2 text-md font-bold' placeholder='000000000'  /></div> 
        <div className='mt-1'><span>Project Info</span><Input className='p-2 text-md font-bold' placeholder='tells us your project details'  /></div>
        <div className='mt-1'><span>Budget</span><Input className='p-2 text-md font-bold' placeholder='your project Budget'  /></div>
        <br />
        <div><span>
          We will contact you soon &#128512;
        
          </span></div>

        <div className='mt-5 w-full flex flex-col justify-center items-center '>
          <Button onClick={sendHandler} className='bg-indigo-500 text-end w-48  text-xl flex justify-center items-center py-5 px-12 border-none  text-white hover:bg-indigo-400 rounded-full'>Send</Button>
         
            <button onClick={()=>{setIsModalOpen(false)}} className='p-1 pt-5'>Close X</button>
        
        {/* <button className='bg-indigo-500 m-5 p-1 px-5 text-xl font-bold rounded-full'>Submit</button> */}
        </div>

    
      
    
        
   
      


      
        
   
    
        
        
     
    

      </div>

    </Modal1>
    <div onClick={showModal} className='w-32 h-10 min-m-5 sm:w-32 sm:h-10 flex justify-center items-center  bg-gradient-to-r from-cyan-400 to-purple-600  rounded-full'>Contact us</div>
    </>
  )
}

export default ContactUsBtn