import React, { Children, useState } from 'react';
import { Button, ConfigProvider, Modal } from 'antd';
const Modal1 = ({isModalOpen,setIsModalOpen,children}) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    console.log('Modal',Children)
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const modalStyles = {
    header: {
    //   borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
      display:"none"
    },
    body: {
      // boxShadow: 'inset 0 0 5px #999',
      borderRadius: 5,
      backgroundColor:"red-200",
    },
    mask: {
      backdropFilter: 'blur(10px)',
    },
    footer: {
      borderTop: '1px solid #333',
      display:"none",
    },
    content: {
      // boxShadow: '0 0 30px #444',
      backdropFilter: 'blur(10px)',
      backgroundColor:'#1D2432'
    }}
  return (
    <>
     <ConfigProvider
        modal={{
          styles: modalStyles,
        }}
      >
      <Modal  className='' title="Basic Modal" open={isModalOpen} onOk={handleOk}  onCancel={handleCancel}>
       {children}
      </Modal>
      </ConfigProvider>
    </>
  );
};
export default Modal1;