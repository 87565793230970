import React from 'react'

function Paragraph1({content,children}) {
  return (
    <div className='p-3'>
        {content}
        {children}

</div>
  )
}

export default Paragraph1