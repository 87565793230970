import React, { useState } from 'react'
import ContactUsBtn from './ContactUs-btn';
import Modal1 from './Modal';
import { Link } from 'react-router-dom';

function Header() {
    const [show, setShow] = useState(false)
    const handler = () => {
        setShow(true);
    }


const closeHandler = () => {

        setShow(false);

    
}

    return (
        <>
            {/* backdrop  */}
            {/* {show &&
                <div className='fixed flex justify-end  z-30 w-full h-full backdrop-blur-xl transition-all duration-500 ease-in-out '>
                    <div onClick={closeHandler} className='absolute bottom-5 z-31 flex w-full  justify-center items-center   text-black rounded-full'>
                        <span className='flex w-10 h-10 text-5xl  justify-center items-center   p-10 rounded-full bg-white'>X</span>
                        </div>

                    <div className='border flex w-full    flex-col justify-center items-center  bg-transparent text-sm  w-4/6 max-w-xl  '>

                        <div    className='text-6xl text-end m-2 text-white'><a onClick={closeHandler} href="#section1 text-xl"> Service</a></div>
                        <div    className='text-6xl text-end m-2 text-white'><a onClick={closeHandler} href="#section2 text-xl"> Portfolio</a></div>
                        <div    className='text-6xl text-end m-2 text-white'><a onClick={closeHandler} href="#section3 text-xl"> Pricing</a></div>
                        <div    className='text-6xl text-end m-2 text-white'><a onClick={closeHandler} href="#section4 text-xl"> About</a></div>
                        <div    className='text-6xl text-end m-2 text-white'><a onClick={closeHandler} href="#section5 text-xl"> FAQ</a></div>


                    </div>
                    <div>





                    </div>

                </div>
            } */}
           <Modal1 isModalOpen={show} setIsModalOpen={setShow} >
                {/* <div className='fixed flex justify-end  z-30 w-full h-full backdrop-blur-xl transition-all duration-500 ease-in-out '> */}
                    

                    <div className=' flex w-full    flex-col justify-center items-center  bg-transparent text-sm  w-4/6 max-w-xl  '>

                        <div    className='text-3xl text-end m-2 text-white'><a onClick={closeHandler} href="#section1"> Service</a></div>
                        {/* <div    className='text-3xl text-end m-2 text-white'><a onClick={closeHandler}><Link to={"/projets"}> Our Works </Link></a></div> */}
                        <div    className='text-3xl text-end m-2 text-white'><a onClick={closeHandler} href="#section3"> Pricing</a></div>
                        <div    className='text-3xl text-end m-2 text-white'><a onClick={closeHandler} href="#section4"> About</a></div>
                        <div    className='text-3xl text-end m-2 text-white'><a onClick={closeHandler} href="#section5"> Why We</a></div>


                    </div>
                    <div onClick={closeHandler} className='mt-10 flex w-full  justify-center items-center   text-black rounded-full'>
                        <span className='flex w-5 h-5 text-3xl hover:bg-indigo-500  justify-center items-center   p-5 rounded-full bg-white'>X</span>
                        </div>





                  

                {/* </div> */}
                
                
                </Modal1>

            <div className='flex justify-between item-center  fixed z-20 border-b border-black  text-white bg-opacity-0 px-6 sm:py-6 py-3 sm:pt-8 pt-4 sm:pb-8 pb-4 backdrop-blur-xl   w-screen '>

                {/* <span className='text-2xl  text-indigo-500  '>Company logo</span> */}
                <div className='sm:text-3xl text-2xl  font-bold'>Zunosoft</div>
                {/* bg-indigo-500 */}
                <div className='sm:flex justify-evenly items-center hidden bg-transparent text-sm  w-4/6 max-w-xl  '>

                    <div className=' md:min-w-20 min-w-16 flex justify-center items-center text-center'><a className='md:text-lg block hover:text-indigo-400 hover:underline hover:font-bold transition-all duration-300 ease-in-out' href="#section1"> Service</a></div>
                    {/* <div className=' md:min-w-20 min-w-16 flex justify-center items-center text-center'><a className='md:text-lg block hover:text-indigo-400 hover:underline hover:font-bold transition-all duration-300 ease-in-out' href="#section2"> <Link to={"/projets"}> Our Works </Link></a></div> */}
                    <div className=' md:min-w-20 min-w-16 flex justify-center items-center text-center'><a className='md:text-lg block hover:text-indigo-400 hover:underline hover:font-bold transition-all duration-300 ease-in-out' href="#section3"> Pricing</a></div>
                    <div className=' md:min-w-20 min-w-16 flex justify-center items-center text-center'><a className='md:text-lg block hover:text-indigo-400 hover:underline hover:font-bold transition-all duration-300 ease-in-out' href="#section4"> About</a></div>
                    <div className=' md:min-w-20 min-w-16 flex justify-center items-center text-center'><a className='md:text-lg block hover:text-indigo-400 hover:underline hover:font-bold transition-all duration-300 ease-in-out' href="#section5"> Why We</a></div>


                </div>
                <div ><ContactUsBtn/></div>
                <div onClick={handler} className='sm:hidden'>Menu</div>
            </div>
        </>
    )
}

export default Header