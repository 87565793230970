import React from 'react'
import globalCss from './globalCss'
import ContactUsBtn from './ContactUs-btn'
import SectionHead from './SectionHead'
import SectionHead2 from './SectionHead2'
function Pricing({id}) {
    return (
        <div id={id} className='w-100 flex justify-center flex-col items-center items-center md:p-10 p-6'>
  <div className=' h-20 w-5'></div>
            <SectionHead name={"Our Pricing"} content={"We offer a unique pricing model for everyone."} />

            <div className={``}>
                <div className=''>
                    <div className='w-full grid lg:grid-cols-3 grid-cols-1 backdrop-blur-sm bg-white/5   rounded-xl max-w-[1000px]'>
                        <div className=' lg:col-span-2 md:p-10 p-5 '>
                            <SectionHead2 name={"What You Will Get"} />
                            <div className={globalCss.paragraph1}>We excels in providing custom software solutions with transparent pricing for confident budgeting. Our skilled team ensures high-quality products, effective communication, and timely delivery.</div>
                            <div className='text-violet-400 font-bold my-2'>What Makes Us Special </div>
                            <hr />
                            <div className='grid  md:grid-cols-2 grid-cols-1 my-2'>
                                <div className='px-5 py-2'>Predictable payment</div>
                                <div className='px-5 py-2'>Flexible hosting preferences</div>
                                <div className='px-5 py-2'>You own what you buy</div>
                                <div className='px-5 py-2'>Customized Metrics and Analytics</div>
                                <div className='px-5 py-2'>Quality Assurance</div>
                                <div className='px-5 py-2'>Effective Communication</div>
                            </div>
                        </div>
                        <div className=' lg:col-span-1 bg-[#020817] flex-col  md:p-10 p-5 lg:rounded-xl  rounded-b-xl flex justify-center items-center'>

                            <div className='lg:xl text-2xl text-gray-300 '> Get Started From Just</div>
                            <div className='m-3'><span className={'text-6xl p-2 font-bold'}>9999</span><span className={'text-4xl text-gray-300'} >rs</span></div>
                            <ContactUsBtn />



                        </div>


                    </div>
                </div>
            </div>


        </div>
    )
}

export default Pricing